<template>
  <div class="w-100 h-100">
    <LineChart
      :is-loading="isLoading"
      :chart-data="chartData"
      :title="title"
      :colors="colors"
    />
  </div>
</template>

<script>
export default {
  data() {
    return {
      isLoading: false,
      title: 'Event Page Views Statistics - 30 days',
      chartData: {},
      colors: [
        this.$themeColors.primary,
        this.$themeColors.dark,
      ],
    }
  },
  created() {
    this.initComponent()
  },
  methods: {
    initComponent() {
      const reportId = this.$route.params.report_name.split('__')[1]

      this.isLoading = true
      this.$http
        .post(`/api/event-reports/analytics`, {
          form_data: {
            fetch_data: ['event_job_visit'],
            report_id: reportId,
          }
        })
        .then(response => {
          const { reports } = response.data
          this.chartData = {
            'Event Job Visit': reports['event_job_visit'],
          }

          this.isLoading = false
        })
        .catch(error => {
          this.isLoading = false
        })
    }
  },
}
</script>

<style>

</style>