<template>
  <b-card
    no-body
  >
    <b-card-header class="align-items-baseline">
      <div>
        <b-card-title class="mb-25">
          {{ title }}
        </b-card-title>
      </div>
    </b-card-header>

    <b-card-body class="pb-0">
      <!-- apex chart -->
      <b-overlay :show="isLoading">
        <vue-apex-charts
          type="line"
          :height="height"
          :options="applicationsChartData.chartOptions"
          :series="applicationsChartData.series"
        />
      </b-overlay>
    </b-card-body>
  </b-card>
</template>

<script>
import VueApexCharts from 'vue-apexcharts'
import { $themeColors } from '@themeConfig'

export default {
  components: {
    VueApexCharts,
  },
  props: {
    reportData: {
      type: Object,
      default: () => {},
    },
    whichPage: {
      type: String,
      default: null,
    },
    title: {
      type: String,
      default: 'Chart',
    },
    section: {
      type: String,
      default: 'job_seeker',
    },
    height: {
      type: String,
      default: 'auto',
    },
    dataName: {
      type: String,
      default: null,
    },
    dataKey: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      isLoading: false,

      applicationsChartData: {
        series: [
          {
            name: 'Applications',
            data: [],
          },
        ],
        chartOptions: {
          chart: {
            toolbar: { show: false },
            zoom: { enabled: false },
            type: 'line',
            dropShadow: {
              enabled: true,
              top: 18,
              left: 2,
              blur: 5,
              opacity: 0.2,
            },
            offsetX: -10,
          },
          stroke: {
            curve: 'smooth',
            width: 4,
          },
          grid: {
            borderColor: '#ebe9f1',
            padding: {
              top: -20,
              bottom: 5,
              left: 20,
            },
          },
          legend: {
            show: false,
          },
          colors: ['#df87f2'],
          fill: {
            type: 'gradient',
            gradient: {
              shade: 'dark',
              inverseColors: false,
              gradientToColors: [$themeColors.primary],
              shadeIntensity: 1,
              type: 'horizontal',
              opacityFrom: 1,
              opacityTo: 1,
              stops: [0, 100, 100, 100],
            },
          },
          markers: {
            size: 0,
            hover: {
              size: 5,
            },
          },
          xaxis: {
            labels: {
              offsetY: 5,
              style: {
                colors: '#b9b9c3',
                fontSize: '0.857rem',
              },
            },
            axisTicks: {
              show: false,
            },
            // categories: [
            //   'Jan',
            //   'Feb',
            //   'Mar',
            //   'Apr',
            //   'May',
            //   'Jun',
            //   'Jul',
            //   'Aug',
            //   'Sep',
            //   'Oct',
            //   'Nov',
            //   'Dec',
            // ],
            categories: [
            ],
            axisBorder: {
              show: false,
            },
            tickPlacement: 'on',
          },
          yaxis: {
            tickAmount: 5,
            labels: {
              style: {
                colors: '#b9b9c3',
                fontSize: '0.857rem',
              },
              formatter(val) {
                return val > 999 ? `${(val / 1000).toFixed(1)}k` : val
              },
            },
          },
          tooltip: {
            x: { show: false },
          },
        },
      },
    }
  },
  watch: {
    reportData(newVal, oldVal) {
      this.updateData(newVal)
    },
    whichPage(newVal, oldVal) {
      this.updateData(this.reportData)
    },
  },
  mounted() {
    if (Object.keys(this.reportData).length > 0) {
      this.updateData(this.reportData)
    }

    for (let step = 1; step < 30; step++) {
      this.applicationsChartData.chartOptions.xaxis.categories.push(step)
    }
  },
  methods: {
    updateData(newVal) {
      // .event_job_visit
      const chartDataJob = newVal.statistics[this.dataKey]
      //   const chartDataVisit = newVal.statistics.job_visit
      //   const chartDataInterested = newVal.statistics.apply_now_clicks
      this.applicationsChartData = {
        series: [
          {
            name: this.dataName,
            data: chartDataJob ? Object.values(chartDataJob) : [],
          },
        //   {
        //     name: 'Event Landing Page Visit',
        //     data: Object.values(chartDataVisit),
        //   },
        //   {
        //     name: 'Interested Applicants',
        //     data: Object.values(chartDataInterested),
        //   },
        ],
        chartOptions: {
          chart: {
            toolbar: { show: false },
            zoom: { enabled: false },
            type: 'line',
            dropShadow: {
              enabled: true,
              top: 18,
              left: 2,
              blur: 5,
              opacity: 0.2,
            },
            offsetX: -10,
          },
          stroke: {
            curve: 'smooth',
            width: 6,
          },
          grid: {
            borderColor: '#ebe9f1',
            padding: {
              top: -20,
              bottom: 5,
              left: 20,
            },
          },
          legend: {
            show: false,
          },
          colors: ['#df87f2'],
          fill: {
            type: 'gradient',
            gradient: {
              shade: 'dark',
              inverseColors: false,
              gradientToColors: [$themeColors.primary, $themeColors.warning],
              shadeIntensity: 1,
              type: 'horizontal',
              opacityFrom: 1,
              opacityTo: 1,
              stops: [0, 100, 100, 100],
            },
          },
          markers: {
            size: 0,
            hover: {
              size: 5,
            },
          },
          xaxis: {
            labels: {
              offsetY: 5,
              style: {
                colors: '#b9b9c3',
                fontSize: '0.857rem',
              },
            },
            axisTicks: {
              show: false,
            },
            categories: chartDataJob ? Object.keys(chartDataJob) : [],
            axisBorder: {
              show: false,
            },
            tickPlacement: 'on',
          },
          yaxis: {
            tickAmount: 5,
            labels: {
              style: {
                colors: '#b9b9c3',
                fontSize: '0.857rem',
              },
              formatter(val) {
                return val > 999 ? `${(val / 1000).toFixed(1)}k` : val
              },
            },
          },
          tooltip: {
            x: { show: false },
          },
        },
      }
    },
  },
}
</script>
