<template>
  <b-card
    no-body
    style="height: 60vh"
  >
    <b-card-header>
      <b-card-title>{{title}}</b-card-title>
    </b-card-header>

    <b-card-body>
      <b-overlay :show="isLoading">
        <slot />
      </b-overlay>
    </b-card-body>
  </b-card>
</template>

<script>
import { $themeColors } from '@themeConfig'

export default {
  props: ['title', 'section'],
  data() {
    return {
      isLoading: false,
    }
  },

  watch: {
  },
  mounted() {
  },
  methods: {

  },
}
</script>
