<template>
  <div class="w-100 h-100">
    <b-card
      no-body
      :style="`height: 60vh;`"
    >
      <b-card-header>
        <b-card-title>Qualification Levels</b-card-title>
      </b-card-header>

      <div
        :style="`height: 80%; overflow-y: scroll; overflow-x: hidden;`"
        class="mx-1"
      >
        <b-overlay :show="isLoading">
          <b-row
            v-for="key in Object.keys(qualificationLevels)"
            :key="key.id"
            class="mb-2 px-1"
          >
            <b-col cols="12">
              <h6>{{ key }}</h6>
            </b-col>
            <b-col cols="12">
              <b-progress
                variant="success"
                :value="qualificationLevels[key]"
                :max="totalQualifications"
                show-value
                animated
              />
            </b-col>
          </b-row>
        </b-overlay>
      </div>
    </b-card>
  </div>
</template>

<script>
export default {
  data() {
    return {
      isLoading: false,
      title: 'Qualification Levels',
      chartData: {},
      qualificationLevels: {},
      colors: [
      ],
    }
  },
  created() {
    this.initComponent()
  },
  methods: {
    initComponent() {
      const reportId = this.$route.params.report_name.split('__')[1]

      this.isLoading = true
      this.$http
        .post(`/api/event-reports/analytics`, {
          form_data: {
            fetch_data: ['qualification_level_list'],
            report_id: reportId,
          }
        })
        .then(response => {
          const { reports } = response.data
          this.chartData = {}
          this.qualificationLevels = reports['qualification_level_list']

          this.isLoading = false
        })
        .catch(error => {
          this.isLoading = false
        })
    }
  },
}
</script>

<style>

</style>