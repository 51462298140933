<template>
  <b-card
    no-body
  >
    <b-card-header>
      <b-card-title>Job Application Statistics</b-card-title>
    </b-card-header>

    <b-card-body>
      <b-overlay :show="isLoading">
        <!-- apex chart -->
        <vue-apex-charts
          type="donut"
          :height="height"
          class="my-1"
          :options="appForm.chartOptions"
          :series="appForm.series"
        />

        <!-- chart info -->
        <div
          v-for="(data, key, index) in chartInfo"
          :key="key"
          class="d-flex justify-content-between"
          :class="index === Object.keys(chartInfo).length - 1 ? '' : 'mb-1'"
        >
          <div class="series-info d-flex align-items-center">
            <feather-icon
              icon="CircleIcon"
              size="16"
              :style="`color: ${data.color}`"
            />
            <span class="font-weight-bold text-capitalize ml-75">{{
              data.title
            }}</span>
          </div>

          <span>{{ data.value }}</span>
        </div>
      </b-overlay>
    </b-card-body>
  </b-card>
</template>

<script>
import VueApexCharts from 'vue-apexcharts'
import { $themeColors } from '@themeConfig'

// :style="`height: ${componentOptions.cardHeight};`"

export default {
  components: {
    VueApexCharts,
  },
  props: {
    reportData: {
      type: Object,
      default: () => { },
    },
    section: {
      type: String,
      default: 'job_seeker',
    },
    height: {
      type: String,
      default: 'auto',
    },
  },
  data() {
    return {
      isLoading: false,

      chartInfo: {
        male: {
          value: 0,
          title: 'Submitted Applications',
          color: '#FF9F43',
        },
        female: {
          value: 0,
          title: 'Abandoned Applications',
          color: '#10163A',
        },
      },
      appForm: {
        series: [],
        chartOptions: {
          chart: {
            toolbar: {
              show: false,
            },
          },
          labels: ['Submitted Applications', 'Abandoned Applications'],
          colors: ['#FF9F43', '#10163A'],
          dataLabels: {
            enabled: false,
          },
          legend: { show: false },
          comparedResult: [2, -3, 8],
          stroke: { width: 0 },
        },
      },
    }
  },
  watch: {
    reportData(newVal, oldVal) {
      this.updateData(newVal)
    },
  },
  mounted() {
    if (Object.keys(this.reportData).length > 0) {
      this.updateData(this.reportData)
    }
  },
  methods: {
    updateData(newVal) {
      // console.log(newVal.statistics.charts)
      const chartData = newVal.statistics.charts.application_form_chart
      // console.log(newVal)
      if (chartData) {
        this.$set(this, 'chartInfo', {
          'Submitted Applications': {
            value: chartData.submitted_applications,
            title: 'Submitted Applications',
            color: '#FF9F43',
          },
          'Abandoned Applications': {
            value: chartData.abandoned_applications,
            title: 'Abandoned Applications',
            color: '#10163A',
          },
        })
      }
      // this.chartInfo = {
      //   male: {
      //     value: chartData.Male,
      //     title: 'Male',
      //     color: '#19a8e6',
      //   },
      //   female: {
      //     value: chartData.Female,
      //     title: 'Female',
      //     color: '#ff3030',
      //   },
      // }
      this.appForm = {
        series: [chartData.submitted_applications, chartData.abandoned_applications],
        chartOptions: {
          chart: {
            toolbar: {
              show: false,
            },
          },
          labels: ['Submitted Applications', 'Abandoned Applications'],
          colors: ['#FF9F43', '#10163A', $themeColors.dark],
          dataLabels: {
            enabled: false,
          },
          legend: { show: false },
          comparedResult: [2, -3, 8],
          stroke: { width: 0 },
        },
      }
    },
  },
}
</script>
